import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Typography from "src/components/Typography"

import * as styles from "./Hero.module.scss"

export function Hero() {
  return (
    <section className={styles.ProcessHero}>
      <article>
        <Typography variant="h1">
          Take a look at
          <br /> our process.
        </Typography>
      </article>
      <div className={styles.ImageWrapper}>
        <StaticImage
          src="../../../../images/services/Website.png"
          alt="Website illustration"
          layout="constrained"
          placeholder="none"
          quality={100}
          width={900}
        />
      </div>
    </section>
  )
}
