import React, { useState, useEffect, useRef } from "react"
import ContactForm from "src/components/Sections/Landing/Partners/ContactForm"
import SlickSlider from "react-slick"
import clsx from "clsx"
import Typography from "src/components/Typography"
import * as styles from "./Slider.module.scss"

const kickoffText =
  "The beginning of cooperation with a client aims to define the business value. We always put a great effort to fully understand client's needs, relations with customers, and internal processes. This approach allows us to outline strategic priorities and align them with the client's expectations. At this stage, we also prepare an estimation of the labour and time needed to complete the project."

const analysisText =
  "At this stage, we meet with stakeholders of the project to even further analyze and understand the expectations from the project. Then we take a deep dive into competition analysis. Finally, we adjust the primary concept and come up with our ideas to ensure our clients that the product will meet every stakeholder's requirements. Depending on the size of the project, at this stage to spark innovativeness we may organize workshops."

const designText =
  "With the knowledge of all the business needs, we proceed to create the product. We begin with definitions of the user's journey to layout usage scenarios. Then we proceed to create a perfect user experience and interface every user will love. At this stage, we prepare wireframes, followed by graphic interactive mockups to deliver intuitive navigation. We also perform usability tests to gather early feedback regarding the designed product."

const developmentText =
  "With the product design accepted we are ready to begin the development. We prefer the agile approach, where we work in sprints, usually lasting two weeks. This flow forces us and the client to ensure a great communication stream that allows our clients to test new features twice each month."

const maintenanceText =
  "When the product is finished we help you to deliver it to the target audience. We will also provide assistance and maintenance to make sure that the product is always performing as intended. The cooperation does not end here - we are always open to talk about future improvements and implementations of new features."

const content = [
  { id: "section_Kickoff", title: "Kickoff", text: kickoffText },
  { id: "section_analysis", title: "Analysis", text: analysisText },
  { id: "section_design", title: "Design", text: designText },
  { id: "section_development", title: "Development", text: developmentText },
  {
    id: "section_implementation",
    title: "Maintenance",
    text: maintenanceText,
  },
]

export default function Slider() {
  const sliderRef = useRef()
  const navigationRef = useRef()
  const navigationWrapperRef = useRef()

  const [currentSlide, setSlide] = useState(content[0])
  const [progressBarProps, setProgressBarProps] = useState({
    width: 0,
    transform: 0,
  })

  useEffect(() => {
    const el = navigationWrapperRef.current.querySelector(
      `#${currentSlide?.id || ""}`
    )

    const newWidth = el?.offsetWidth || 0
    const newPosition = el?.offsetLeft || 0

    setProgressBarProps({ width: newWidth, transform: newPosition })
    return () => {}
  }, [currentSlide])

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    dots: false,
    arrows: false,
    swipe: false,
  }

  const { width, transform } = progressBarProps

  function selectSlide(target) {
    const slideIndex = content.findIndex(slide => slide.id === target.id) || 0
    setSlide(target)
    sliderRef.current.slickGoTo(slideIndex)
  }
  return (
    <section className={styles.SectionWrapper}>
      <header className={styles.SectionNavigation}>
        <div
          ref={navigationWrapperRef}
          className={styles.NavigationContentWrapper}
        >
          <div
            className={styles.ProgressBar}
            ref={navigationRef}
            style={{
              width: width,
              transform: `translateX(${transform}px)`,
            }}
          ></div>

          {content.map(slide => (
            <button
              key={`Nav_button_${slide.id}`}
              id={slide.id}
              onClick={() => selectSlide(slide)}
            >
              <span
                className={
                  currentSlide?.id === slide?.id
                    ? styles.ButtonContentActive
                    : styles.ButtonContent
                }
              >
                {slide?.title || ""}
              </span>
            </button>
          ))}
        </div>
      </header>

      <div className={styles.SliderWrapper}>
        <SlickSlider ref={sliderRef} {...settings} className={styles.Slider}>
          {content.map(({ id, text }) => (
            <div key={`article_${id}`}>
              <article
                className={clsx(styles.SliderItem, "animated-slider-content")}
              >
                <Typography variant="p" className={styles.CustomParagraph}>
                  {text}
                </Typography>
              </article>
            </div>
          ))}
        </SlickSlider>
      </div>
    </section>
  )
}
