import React, { useContext, useEffect, useLayoutEffect } from "react"

import Layout from "src/components/Layout"
import Seo from "../components/seo"
import ThemeContext, { Themes } from "src/theme/context"
import Hero from "src/components/Sections/Process/Hero"
import gsap from "gsap"
import Slider from "src/components/Sections/Process/Slider/Slider"
import Contact from "src/components/Sections/Process/Contact"

const Process = () => {
  const { changeTheme } = useContext(ThemeContext)
  useEffect(() => {
    console.log("PROCESS THEME")
    changeTheme(Themes.default)
  }, [])

  useLayoutEffect(() => {
    if (window) {
      gsap.to(window, {
        duration: 0.2,
        ease: "power2.out",
        y: 0,
      })
    }
  }, [])
  return (
    <Layout
      footerTheme={Themes.secondary}
      footerStyles={{ backgroundColor: "#6D7092" }}
    >
      <Seo title="How we do it" description="We build digital products." />
      <Hero />
      <Slider />
      <Contact />
    </Layout>
  )
}

export default Process
